import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Components/Home";
import Projects from "../Components/HomePageComponents/Projects";
import Users from "../Components/HomePageComponents/Users";
import Roadmaps from "../Components/HomePageComponents/Roadmaps";
import Analytics from "../Components/HomePageComponents/Analytics";
import ProtectedRoute from "./ProtectedRoute";
import QA from "../Components/HomePageComponents/QA";

const DashboardRoutes = ({ userId }) => (
  <Routes>
    {/* Wrap Home as a persistent layout */}
    <Route
      path="/"
      element={
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      }
    >
      {/* Nested routes */}
      <Route index element={<Projects />} /> {/* Default route */}
      <Route path="projects" element={<Projects />} />
      <Route path="users" element={<Users />} />
      <Route path="roadmaps" element={<Roadmaps />} />
      <Route path="analytics" element={<Analytics />} />
      <Route path="/qa" element={<QA userId={userId} />} />
    </Route>
  </Routes>
);

export default DashboardRoutes;
