import { db } from "../../Firebase/firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";

const fetchProjectEvents = async (projectId) => {
  const eventsRef = collection(db, "projects", projectId, "events");
  const q = query(eventsRef);

  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export default fetchProjectEvents;
