import React, { useContext, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AuthContext } from "../../Context/AuthContext";
import fetchProjectEvents from "./fetchProjectEvents"; // Utility function to fetch events
import CalendarModal from "./CalendarModal"; // Modal component for displaying events
import useProjects from "../useProjects"; // Custom hook for fetching projects
import { ThemeContext } from "../../Theme/ThemeContext"; // Import ThemeContext

const Projects = () => {
  const { user } = useContext(AuthContext); // Get current user from AuthContext
  const { theme } = useContext(ThemeContext); // Access theme properties
  const { projects, loading, addProject } = useProjects(user?.uid); // Fetch projects for the current user
  const [open, setOpen] = useState(false); // Dialog state for adding a project
  const [calendarOpen, setCalendarOpen] = useState(false); // Modal state for displaying events
  const [selectedProjectEvents, setSelectedProjectEvents] = useState([]); // State for storing selected project events
  const [selectedProjectId, setSelectedProjectId] = useState(null); // State to track selected project ID
  const [newProject, setNewProject] = useState({
    name: "",
    description: "",
    language: "",
    type: "",
    version: "1.1.1",
  });

  // Open the "Add Project" dialog
  const handleOpen = () => setOpen(true);

  // Close the "Add Project" dialog
  const handleClose = () => setOpen(false);

  // Handle adding a new project
  const handleAddProject = async () => {
    if (newProject.name.trim()) {
      await addProject({
        ...newProject,
        userId: user.uid, // Link project to the current user
        createdAt: new Date(), // Timestamp of creation
      });
      // Reset form and close dialog
      setNewProject({
        name: "",
        description: "",
        language: "",
        type: "",
        version: "1.1.1",
      });
      handleClose();
    }
  };

  // Handle card click to fetch events and open calendar
  const handleCardClick = async (projectId) => {
    try {
      const events = await fetchProjectEvents(projectId); // Fetch events for the project
      setSelectedProjectEvents(events); // Update state with fetched events
      setSelectedProjectId(projectId); // Set the selected project ID
      setCalendarOpen(true); // Open the calendar modal
      console.log("Fetched events:", events);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  // Close the calendar modal
  const handleCloseCalendar = () => setCalendarOpen(false);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: theme.background,
        color: theme.text,
        padding: 3,
      }}
    >
      {/* Page Title */}
      <Typography variant="h4" gutterBottom sx={{ color: theme.primary }}>
        Projects
      </Typography>

      {/* Add Project Button */}
      <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
        <Fab color="primary" aria-label="add" onClick={handleOpen}>
          <AddIcon />
        </Fab>
      </Box>

      {/* Add Project Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Project</DialogTitle>
        <DialogContent>
          <TextField
            label="Project Name"
            fullWidth
            value={newProject.name}
            onChange={(e) =>
              setNewProject({ ...newProject, name: e.target.value })
            }
            sx={{
              marginBottom: 2,
              input: { color: theme.text },
              label: { color: theme.accent },
            }}
          />
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={newProject.description}
            onChange={(e) =>
              setNewProject({ ...newProject, description: e.target.value })
            }
            sx={{
              marginBottom: 2,
              input: { color: theme.text },
              label: { color: theme.accent },
            }}
          />
          <TextField
            label="Language"
            fullWidth
            value={newProject.language}
            onChange={(e) =>
              setNewProject({ ...newProject, language: e.target.value })
            }
            sx={{
              marginBottom: 2,
              input: { color: theme.text },
              label: { color: theme.accent },
            }}
          />
          <TextField
            label="Type"
            fullWidth
            value={newProject.type}
            onChange={(e) =>
              setNewProject({ ...newProject, type: e.target.value })
            }
            sx={{
              marginBottom: 2,
              input: { color: theme.text },
              label: { color: theme.accent },
            }}
          />
          <TextField
            label="Version"
            fullWidth
            value={newProject.version}
            onChange={(e) =>
              setNewProject({ ...newProject, version: e.target.value })
            }
            sx={{
              marginBottom: 2,
              input: { color: theme.text },
              label: { color: theme.accent },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddProject}
            sx={{ marginTop: 2, backgroundColor: theme.primary }}
          >
            Add Project
          </Button>
        </DialogContent>
      </Dialog>

      {/* Projects List */}
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <Grid container spacing={3} sx={{ marginTop: 2 }}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Card
                onClick={() => handleCardClick(project.id)}
                sx={{
                  cursor: "pointer",
                  backgroundColor: theme.card || "#333333",
                  color: theme.text,
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ color: theme.primary }}>
                    {project.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {project.description}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Language: {project.language}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Type: {project.type}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Version: {project.version}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Calendar Modal */}
      <CalendarModal
        open={calendarOpen}
        onClose={handleCloseCalendar}
        events={selectedProjectEvents}
        projectId={selectedProjectId}
        setSelectedProjectEvents={setSelectedProjectEvents}
      />
    </Box>
  );
};

export default Projects;
