import React, { useState, useContext } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { auth } from "./Firebase/firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "./Theme/ThemeContext"; // Import the ThemeContext

const Auth = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Track loading state
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext); // Access theme properties

  const handleSubmit = async () => {
    setLoading(true); // Show loader when authentication starts
    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      setError(""); // Clear error on success
      navigate("/");
    } catch (err) {
      setError(err.message); // Display error message
    } finally {
      setLoading(false); // Hide loader after authentication completes
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: theme.background, // Full-page background
        color: theme.text,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Backdrop
        open={loading}
        sx={{
          color: theme.primary,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container
        maxWidth="xs"
        sx={{
          backgroundColor: theme.card || "#333333", // Dark grey background for the card
          color: theme.text, // Text color inside the card
          padding: 3,
          borderRadius: 2,
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.8)", // Enhanced shadow for dark mode
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: theme.primary }}
        >
          {isSignUp ? "Sign Up" : "Login"}
        </Typography>
        {error && (
          <Typography variant="body2" color="error" align="center">
            {error}
          </Typography>
        )}
        <TextField
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          InputProps={{
            style: { color: theme.text },
          }}
          InputLabelProps={{
            style: { color: theme.accent },
          }}
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          InputProps={{
            style: { color: theme.text },
          }}
          InputLabelProps={{
            style: { color: theme.accent },
          }}
        />
        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: 2,
            backgroundColor: theme.primary,
            color: theme.text,
            "&:hover": {
              backgroundColor: theme.accent,
            },
          }}
          onClick={handleSubmit}
        >
          {isSignUp ? "Sign Up" : "Login"}
        </Button>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              color="secondary"
              onClick={() => setIsSignUp(!isSignUp)}
              sx={{ mt: 2, color: theme.primary }}
            >
              {isSignUp
                ? "Already have an account? Login"
                : "Don't have an account? Sign Up"}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Auth;
