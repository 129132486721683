import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../Firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { CircularProgress } from "@mui/material";
// Wherever you have access to userId (e.g., after authentication)
// Import Redux hooks and actions
import { useDispatch } from "react-redux";
import { setUserId } from "../slices/userSlice";

export const AuthContext = createContext({ user: null });

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  // Function to fetch user data from Firestore
  const fetchUserData = async (uid) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const userSnapshot = await getDoc(userDocRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        return {
          uid: uid,
          email: userData.email,
          teamId: userData.teamId || null,
          role: userData.role || "member",
          name: userData.name || "Unknown",
          permissions: userData.permissions || null,
        };
      } else {
        console.error("Firestore user document not found for UID:", uid);
        return null;
      }
    } catch (error) {
      console.error("Error fetching Firestore user data:", error);
      return null;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        setLoading(true); // Start loading state
        const userData = await fetchUserData(firebaseUser.uid);

        if (userData) {
          setUser(userData);
          console.log("User data: ", userData);
          // When userId is available
          dispatch(setUserId(userData));
        } else {
          // Retry fetching Firestore data if it fails (e.g., document not created yet)
          console.warn("Retrying Firestore user document fetch...");
          setTimeout(async () => {
            const retriedUserData = await fetchUserData(firebaseUser.uid);
            setUser(retriedUserData || null);
          }, 2000); // Retry after 2 seconds
        }
      } else {
        setUser(null);
      }
      setLoading(false); // End loading state
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // console.log("Authenticated User:", user);
  }, [user]);

  if (loading) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};
