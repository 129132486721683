import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Fab,
  Modal,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { AuthContext } from "../../Context/AuthContext";
import createUserApi from "../../utils/createUserApi";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../Firebase/firebaseConfig";

const Users = () => {
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    name: "",
    role: "member",
    teamId: user?.teamId || "",
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      if (!user || !user.teamId) return;

      setLoading(true);
      try {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("teamId", "==", user.teamId));
        const snapshot = await getDocs(q);
        const usersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [user]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewUser({
      email: "",
      name: "",
      role: "member",
      teamId: user?.teamId || "",
    });
    setError("");
    setSuccessMessage("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({ ...prev, [name]: value }));
  };
  const handleCreateUser = async () => {
    setError("");
    setSuccessMessage("");

    if (!newUser.email || !newUser.name) {
      setError("Name and Email are required.");
      return;
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newUser.email)) {
      setError("Invalid email format.");
      return;
    }

    // Validate teamId
    if (!user.teamId) {
      setError("Team ID is missing.");
      return;
    }

    try {
      const userDetails = {
        ...newUser,
        teamId: user.teamId,
        password: "temporaryPassword123", // Default password
      };

      const result = await createUserApi(userDetails);
      setSuccessMessage(`User created successfully with ID: ${result.userId}`);

      // Refresh user list
      setUsers((prevUsers) => [
        ...prevUsers,
        { ...userDetails, userId: result.userId },
      ]);

      handleClose();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Users Management
      </Typography>

      <Paper sx={{ padding: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell>
                <strong>Email</strong>
              </TableCell>
              <TableCell>
                <strong>Role</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Loading...
                </TableCell>
              </TableRow>
            ) : users.length > 0 ? (
              users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name || "N/A"}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No users found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      {/* Floating Action Button */}
      <Fab
        color="primary"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        onClick={handleOpen}
      >
        <AddIcon />
      </Fab>

      {/* Modal for Adding Users */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            p: 4,
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add New User
          </Typography>
          {error && (
            <Typography variant="body2" color="error" gutterBottom>
              {error}
            </Typography>
          )}
          {successMessage && (
            <Typography variant="body2" color="success" gutterBottom>
              {successMessage}
            </Typography>
          )}
          <TextField
            label="Name"
            name="name"
            value={newUser.name}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            value={newUser.email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Role"
            name="role"
            value={newUser.role}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateUser}
            fullWidth
            sx={{ mt: 2 }}
          >
            Create User
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Users;
