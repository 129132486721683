import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../Firebase/firebaseConfig";
import { useSelector } from "react-redux";

const QA = () => {
  const [events, setEvents] = useState([]);
  const userId = useSelector((state) => state.user.userId);

  useEffect(() => {
    const fetchProjectEndDates = async () => {
      try {
        console.log("Fetching project end dates...");
        console.log("User ID from Redux:", userId);

        const projectsRef = collection(db, "projects");
        const q = query(projectsRef, where("userId", "==", userId.uid));
        const projectSnapshots = await getDocs(q);

        console.log("Project snapshot size:", projectSnapshots.size);

        const fetchedEvents = [];

        for (const projectDoc of projectSnapshots.docs) {
          const projectId = projectDoc.id;
          const projectData = projectDoc.data();
          console.log("Project ID:", projectId, "Data:", projectData);

          // Fetch events for the project
          const eventsRef = collection(db, "projects", projectId, "events");
          const eventsSnapshot = await getDocs(eventsRef);

          console.log(
            `Events snapshot size for project ${projectId}:`,
            eventsSnapshot.size
          );

          eventsSnapshot.forEach((eventDoc) => {
            const eventData = eventDoc.data();
            console.log("Event document data:", eventData);

            if (eventData.end) {
              const formattedEvent = {
                title: eventData.title || "Unnamed Event",
                start: eventData.end.toDate().toISOString().split("T")[0],
                allDay: true,
              };

              console.log("Formatted Event:", formattedEvent);
              fetchedEvents.push(formattedEvent);
            } else {
              console.warn(
                `Event in project ${projectId} is missing an endDate:`,
                eventData
              );
            }
          });
        }

        console.log("Final mapped events for FullCalendar:", fetchedEvents);
        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error fetching project end dates:", error);
      }
    };

    fetchProjectEndDates();
  }, [userId]);

  return (
    <div style={{ padding: "20px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>QA Calendar</h2>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={events}
        height="100vh"
      />
    </div>
  );
};

export default QA;
