// src/Routes/Routes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Components/Home";
import Auth from "../Auth";
import DashboardRoutes from "./DashboardRoutes";

const AppRoutes = () => (
  <Routes>
    <Route path="/*" element={<DashboardRoutes />} />
    <Route path="/auth" element={<Auth />} />
  </Routes>
);

export default AppRoutes;
