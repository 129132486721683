import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import { db } from "../../Firebase/firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { AuthContext } from "../../Context/AuthContext";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineConnector from "@mui/lab/TimelineConnector";
import { FrappeGantt } from "frappe-gantt-react";

const Roadmaps = () => {
  const { user } = useContext(AuthContext); // Get user from AuthContext
  const [events, setEvents] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("all");
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true); // Start loading
      try {
        const userId = user?.uid; // Get authenticated user ID
        if (!userId) throw new Error("User ID not found!");

        // Fetch projects belonging to the user
        const projectQuery = query(
          collection(db, "projects"),
          where("userId", "==", userId)
        );
        const projectSnapshot = await getDocs(projectQuery);
        const projectIds = projectSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));

        // Fetch events for each project
        const eventPromises = projectIds.map(async ({ id: projectId }) => {
          const eventsQuery = query(
            collection(db, "projects", projectId, "events")
          );
          const eventsSnapshot = await getDocs(eventsQuery);
          return eventsSnapshot.docs.map((doc) => {
            const eventData = doc.data();
            return {
              id: doc.id,
              projectId,
              ...eventData,
              start: eventData.start?.toDate() || null,
              end: eventData.end?.toDate() || null,
            };
          });
        });

        const allEvents = (await Promise.all(eventPromises)).flat();

        // Filter out projects with no valid events
        const validEvents = allEvents.filter(
          (event) => event.start instanceof Date && event.end instanceof Date
        );
        const projectsWithEvents = projectIds.filter((project) =>
          validEvents.some((event) => event.projectId === project.id)
        );

        // Sort events by start date
        const sortedEvents = validEvents.sort((a, b) => a.start - b.start);

        setEvents(sortedEvents);
        setProjects(projectsWithEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchEvents();
  }, [user]);

  const filteredEvents =
    selectedProject === "all"
      ? events
      : events.filter((e) => e.projectId === selectedProject);

  // Gantt chart data preparation
  const ganttData = filteredEvents.map((event) => {
    const safeStart = event.start instanceof Date ? event.start : new Date();
    const safeEnd = event.end instanceof Date ? event.end : new Date();

    return {
      id: event.id,
      name: event.title || "Unnamed Event", // Fallback title
      start: safeStart.toISOString().split("T")[0], // Safe formatting for Gantt
      end: safeEnd.toISOString().split("T")[0], // Safe formatting for Gantt
      progress: 50, // Default progress
      dependencies: "", // No dependencies in this example
    };
  });

  if (loading) {
    return (
      <Box>
        <Typography variant="h6" align="center" sx={{ marginTop: 3 }}>
          Loading Roadmap...
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Roadmap for 2024
      </Typography>

      {/* Project Filter */}
      <Select
        value={selectedProject}
        onChange={(e) => setSelectedProject(e.target.value)}
        displayEmpty
        sx={{ marginBottom: 3 }}
      >
        <MenuItem value="all">All Projects</MenuItem>
        {projects.map((project) => (
          <MenuItem key={project.id} value={project.id}>
            {project.name}
          </MenuItem>
        ))}
      </Select>

      {/* Timeline Visualization */}
      <Typography variant="h6" gutterBottom>
        Pictorial Representation
      </Typography>
      <Paper sx={{ padding: 3, marginBottom: 5 }}>
        <Timeline>
          {filteredEvents.map((event) => (
            <TimelineItem key={event.id}>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="subtitle1">{event.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {event.start?.toLocaleDateString()} -{" "}
                  {event.end?.toLocaleDateString()}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Paper>

      {/* Gantt Chart Visualization */}
      <Typography variant="h6" gutterBottom>
        Gantt Chart Representation
      </Typography>
      <Paper sx={{ padding: 3, marginBottom: 5 }}>
        <FrappeGantt
          tasks={ganttData.length ? ganttData : []} // Fallback to empty array if no data
          viewMode="Month" // Adjust view mode to "Day", "Week", or "Month"
          onClick={(task) => console.log("Clicked task:", task)} // Optional: Handle task clicks
        />
      </Paper>

      {/* Text-Based Representation */}
      <Typography variant="h6" gutterBottom>
        Text-Based Representation
      </Typography>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Duration (Days)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredEvents.map((event) => (
              <TableRow key={event.id}>
                <TableCell>
                  {projects.find((p) => p.id === event.projectId)?.name ||
                    "Unknown"}
                </TableCell>
                <TableCell>{event.title}</TableCell>
                <TableCell>{event.start?.toLocaleDateString()}</TableCell>
                <TableCell>{event.end?.toLocaleDateString()}</TableCell>
                <TableCell>
                  {Math.ceil((event.end - event.start) / (1000 * 60 * 60 * 24))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default Roadmaps;
