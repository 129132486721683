import React, { createContext, useState, useEffect } from "react";

// Define light and dark themes
const themes = {
  light: {
    primary: "#ADD8E6",
    background: "#FFFFFF",
    text: "#000000",
    card: "#FFFFFF", // Light theme card background
    accent: "#CCCCCC",
  },
  dark: {
    primary: "#ADD8E6",
    background: "#121212",
    text: "#FFFFFF",
    card: "#333333", // Dark grey card background
    accent: "#444444",
  },
};

// Create the context
const ThemeContext = createContext();

const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(themes.dark); // Initialize with light theme

  // Toggle theme function
  const toggleTheme = () => {
    setTheme((prev) => (prev === themes.light ? themes.dark : themes.light));
  };

  // Apply theme to CSS variables
  useEffect(() => {
    if (!theme) {
      console.error("Theme is undefined or null.");
      return; // Ensure `theme` is not null or undefined before applying
    }

    const root = document.documentElement;
    Object.entries(theme).forEach(([key, value]) => {
      root.style.setProperty(`--${key}`, value);
    });
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
