import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { collection, doc, getDoc, updateDoc, addDoc } from "firebase/firestore";
import { db } from "../../Firebase/firebaseConfig";
import fetchProjectEvents from "./fetchProjectEvents";

const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A8", "#F3FF33"];

const assignRandomColor = () =>
  colors[Math.floor(Math.random() * colors.length)];

const CalendarModal = ({
  open,
  onClose,
  events,
  projectId,
  setSelectedProjectEvents,
}) => {
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [editEventModalOpen, setEditEventModalOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: "",
    description: "",
    start: "",
    end: "",
    allDay: false,
  });
  const [selectedEvent, setSelectedEvent] = useState(null);

  const normalizeEvents = (events) =>
    events.map((event) => ({
      id: event.id,
      title: event.title || "Untitled Event",
      start: event.start?.toDate().toISOString(),
      end:
        event.end?.toDate().toISOString() ||
        event.start?.toDate().toISOString(),
      allDay: event.allDay || false,
      color: event.color || assignRandomColor(),
      description: event.description || "",
      comments: event.comments || [],
    }));

  const formattedEvents = normalizeEvents(events);

  const handleDateClick = (info) => {
    setNewEvent({ ...newEvent, start: info.dateStr, end: info.dateStr });
    setEventModalOpen(true);
  };

  const handleEventClick = async (info) => {
    console.log("Event clicked:", info); // Debug log to check the event data

    const eventId = info.event.id; // Extract the event ID
    console.log("Event ID:", eventId);

    if (!eventId) {
      alert("Invalid event clicked!");
      return;
    }

    try {
      const eventDoc = doc(db, "projects", projectId, "events", eventId);
      const eventSnapshot = await getDoc(eventDoc);
      if (eventSnapshot.exists()) {
        const eventData = eventSnapshot.data();
        console.log("Fetched Event Data:", eventData); // Debug log for fetched data

        const startDate =
          eventData.start && eventData.start.toDate
            ? eventData.start.toDate().toISOString()
            : "";
        const endDate =
          eventData.end && eventData.end.toDate
            ? eventData.end.toDate().toISOString()
            : startDate; // Default to startDate if endDate is missing

        setSelectedEvent({
          id: eventId,
          title: eventData.title || "",
          description: eventData.description || "",
          start: startDate,
          end: endDate,
          comments: eventData.comments || [],
        });
        setEditEventModalOpen(true);
      } else {
        console.error("Event not found in Firestore.");
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    }
  };

  const handleEventModalClose = () => {
    setEventModalOpen(false);
    setNewEvent({
      title: "",
      description: "",
      start: "",
      end: "",
      allDay: false,
    });
  };

  const handleEditEventModalClose = () => {
    setEditEventModalOpen(false);
    setSelectedEvent(null);
  };

  const handleAddEvent = async () => {
    if (!newEvent.start || !newEvent.end) {
      alert("Please provide both start and end dates.");
      return;
    }
    if (new Date(newEvent.end) < new Date(newEvent.start)) {
      alert("End date cannot be earlier than start date.");
      return;
    }

    try {
      const eventRef = collection(db, "projects", projectId, "events");
      await addDoc(eventRef, {
        ...newEvent,
        start: new Date(newEvent.start),
        end: new Date(newEvent.end),
        createdAt: new Date(),
        color: assignRandomColor(),
      });
      handleEventModalClose();

      const updatedEvents = await fetchProjectEvents(projectId);
      setSelectedProjectEvents(updatedEvents);
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  const handleEditEvent = async () => {
    if (!selectedEvent.start || !selectedEvent.end) {
      alert("Please provide both start and end dates.");
      return;
    }
    if (new Date(selectedEvent.end) < new Date(selectedEvent.start)) {
      alert("End date cannot be earlier than start date.");
      return;
    }

    try {
      const eventRef = doc(
        db,
        "projects",
        projectId,
        "events",
        selectedEvent.id
      );
      await updateDoc(eventRef, {
        title: selectedEvent.title,
        description: selectedEvent.description,
        start: new Date(selectedEvent.start),
        end: new Date(selectedEvent.end),
        comments: selectedEvent.comments || [],
      });
      handleEditEventModalClose();

      const updatedEvents = await fetchProjectEvents(projectId);
      setSelectedProjectEvents(updatedEvents);
    } catch (error) {
      console.error("Error editing event:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography variant="h6">Project Calendar</Typography>
        <Button onClick={onClose} variant="contained" color="secondary">
          Close
        </Button>
      </Box>
      <DialogContent>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={formattedEvents}
          dateClick={handleDateClick}
          eventClick={handleEventClick} // Ensure this handler is correctly bound
        />
      </DialogContent>

      {/* Add Event Modal */}
      <Dialog open={eventModalOpen} onClose={handleEventModalClose}>
        <DialogTitle>Add Event</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            fullWidth
            value={newEvent.title}
            onChange={(e) =>
              setNewEvent({ ...newEvent, title: e.target.value })
            }
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={newEvent.description}
            onChange={(e) =>
              setNewEvent({ ...newEvent, description: e.target.value })
            }
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Start Date"
            type="datetime-local"
            fullWidth
            value={newEvent.start}
            onChange={(e) =>
              setNewEvent({ ...newEvent, start: e.target.value })
            }
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="End Date"
            type="datetime-local"
            fullWidth
            value={newEvent.end}
            onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEventModalClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddEvent} color="primary" variant="contained">
            Add Event
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Event Modal */}
      {selectedEvent && (
        <Dialog open={editEventModalOpen} onClose={handleEditEventModalClose}>
          <DialogTitle>Edit Event</DialogTitle>
          <DialogContent>
            <TextField
              label="Title"
              fullWidth
              value={selectedEvent.title}
              onChange={(e) =>
                setSelectedEvent({ ...selectedEvent, title: e.target.value })
              }
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={selectedEvent.description}
              onChange={(e) =>
                setSelectedEvent({
                  ...selectedEvent,
                  description: e.target.value,
                })
              }
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Start Date"
              type="datetime-local"
              fullWidth
              value={selectedEvent.start}
              onChange={(e) =>
                setSelectedEvent({
                  ...selectedEvent,
                  start: e.target.value,
                })
              }
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="End Date"
              type="datetime-local"
              fullWidth
              value={selectedEvent.end}
              onChange={(e) =>
                setSelectedEvent({
                  ...selectedEvent,
                  end: e.target.value,
                })
              }
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Comments"
              fullWidth
              multiline
              rows={3}
              value={selectedEvent.comments?.join("\n")}
              onChange={(e) =>
                setSelectedEvent({
                  ...selectedEvent,
                  comments: e.target.value.split("\n"),
                })
              }
              sx={{ marginBottom: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditEventModalClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleEditEvent}
              color="primary"
              variant="contained"
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Dialog>
  );
};

export default CalendarModal;
