import React from "react";
import { Box, CssBaseline } from "@mui/material";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../Firebase/firebaseConfig";

const Home = () => {
  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Box>
      <CssBaseline />
      {/* Navbar is persistent */}
      <Navbar onLogout={handleLogout} />
      <Box>
        <Outlet /> {/* Render nested routes here */}
      </Box>
    </Box>
  );
};

export default Home;
