// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";

const store = configureStore({
  reducer: {
    user: userReducer, // Add the user slice reducer
  },
});

export default store;
