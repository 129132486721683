import React, { useContext } from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { ThemeContext } from "../Theme/ThemeContext"; // Import ThemeContext
import Brightness4Icon from "@mui/icons-material/Brightness4"; // Theme toggle icon
import Brightness7Icon from "@mui/icons-material/Brightness7"; // Alternate icon for light mode
import LogoutIcon from "@mui/icons-material/Logout"; // Logout icon

const Navbar = ({ onLogout }) => {
  const { theme, toggleTheme } = useContext(ThemeContext); // Access theme and toggleTheme

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.background, color: theme.text }}
    >
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Prod Route
        </Typography>

        <IconButton color="inherit" component={Link} to="/">
          Projects
        </IconButton>
        <IconButton color="inherit" component={Link} to="/users">
          Users
        </IconButton>
        <IconButton color="inherit" component={Link} to="/roadmaps">
          Roadmaps
        </IconButton>
        <IconButton color="inherit" component={Link} to="/qa">
          QA
        </IconButton>

        {/* Theme Toggle Button */}
        <IconButton color="inherit" onClick={toggleTheme}>
          {theme.background === "#121212" ? (
            <Brightness7Icon /> // Light mode icon for dark theme
          ) : (
            <Brightness4Icon /> // Dark mode icon for light theme
          )}
        </IconButton>

        {/* Logout Button */}
        <IconButton color="inherit" onClick={onLogout}>
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
